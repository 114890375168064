import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container, Grid, Input } from '@material-ui/core'
import {
  CollectionsBookmark,
  ColorLens,
  Public,
  Business,
  Eco,
  Computer,
  LocalHospital,
  Build,
  Work,
} from '@material-ui/icons'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CourseWrapper from '../../components/CourseWrapper'
import logoABED from '../../images/logo-abed.png'
import { PageTitle } from '../../components/PageTitle'
import {
  SectionTitle,
  FilterButton,
  SearchBar,
} from '../../styles/pages/presencial'

import PreHeader from '../../components/PreHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import GlobalStyles from '../../styles/global'

export default function TecnicoEadPage(props) {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  const [filteredCourses, setFilteredCourses] = useState(edges)
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    const posts = filteredCourses.filter(({ node }) =>
      node.frontmatter.name.toLowerCase().includes(query.normalize("NFC").toLowerCase())
    )
    setSearchResults(posts)
  }, [query.toLowerCase()])

  const filterCourses = filter => {
    switch (filter) {
      case 'educacao':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('educacao'))
        )
        break
      case 'artes':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('artes'))
        )
        break
      case 'jornalismo':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('jornalismo'))
        )
        break
      case 'negocios':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('negocios'))
        )
        break
      case 'computacao':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('computacao'))
        )
        break
      case 'engenharia':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('engenharia'))
        )
        break
      case 'agricultura':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('agricultura'))
        )
        break
      case 'saude':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('saude'))
        )
        break
      case 'servicos':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('servicos'))
        )
        break
    }
  }

  return (
    <>
      <PreHeader />
      <Header polo={true} />
      <GlobalStyles />
      <SEO title="Cursos Técnicos" />

      <Container style={{ marginTop: 70 }}>
        <PageTitle>Cursos Técnicos Presenciais</PageTitle>

        <p
          style={{
            marginTop: 50,
            fontSize: 18,
            color: '#333',
            textAlign: 'justify',
            lineHeight: '1.8em',
            wordSpacing: 8,
          }}
        >
          A Faculdade Jardins foi Credenciada/Autorizada para atuar na Educação Profissional
          Técnica de Nível Médio mediante Ato Autorizativo da SETEC - Secretaria de Educação
          Profissional e Tecnológica / Ministério da Educação, após procedimento de habilitação
          que contemplou, inicialmente, a autorização dos Cursos abaixo que se encontram a
          disposição em Aracaju na modalidade presencial e EaD e em todo o Brasil na modalidade
          a distância, com previsão de ampliação da oferta de muito mais Cursos ainda em 2020.
        </p>
      </Container>
      <Container disableGutters={true} style={{ marginTop: 60 }}>
        <SectionTitle>Encontre aqui o seu curso</SectionTitle>

        <SearchBar>
          <Input
            placeholder="Pesquisar curso"
            value={query}
            onChange={e => setQuery(e.target.value)}
            style={{ padding: 6, borderRadius: 5, border: '2px solid' }}
          />
        </SearchBar>

        <div>
          {searchResults.map(({ node }) => (
            <CourseWrapper
              name={node.frontmatter.name}
              // img={node.frontmatter.image.publicURL}
              duration={node.frontmatter.duration}
              price={node.frontmatter.price}
              parcel={node.frontmatter.parcelas}
              link={node.fields.slug}
              key={node.frontmatter.price}
            />
          ))}
        </div>
      </Container>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___name], order: ASC }
      filter: { frontmatter: { category: { eq: "tecnico presencial" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            area
            price
            duration
            parcelas
            subscribe
          }
        }
      }
    }
  }
`
